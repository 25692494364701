<template>
  <v-container>
    <!-- <h3>{{ $t("message.code") }}</h2> -->
    <h2>{{ $t("message.config-create") }}</h2>
    <form class="mt-5">
      <v-row>
        <v-col class="ms-10" cols="2" md="3">
          {{ $t("message.code") }}
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field v-model="form.code" outlined required></v-text-field>
        </v-col>
        <v-col class="ms-10" cols="2" md="3">
          {{ $t("message.label-description") }}
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field v-model="form.description" outlined required></v-text-field>
        </v-col>
        <v-col class="ms-10" cols="2" md="3">
          {{ $t("message.value") }}
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field v-model="form.value" outlined required></v-text-field>
        </v-col>
      </v-row>
      <v-card class="d-flex justify-space-between mb-6 mt-9 mx-7" flat tile>
        <v-card class="pa-2" flat tile>
          <v-btn color="grey" depressed @click="$router.go(-1)">
            {{ $t("message.btn-back") }}
          </v-btn>
        </v-card>
        <v-card class="pa-2" flat tile>
          <v-btn color="primary" depressed @click.prevent="submitForm">
            {{ $t("message.btn-submit") }}
          </v-btn>
        </v-card>
      </v-card>
    </form>
  </v-container>
</template>

<script>
export default {
  name: "CreateConfig",
  data: () => ({
    form: {
      code: "",
      description: "",
      value: ""
    }
  }),
  computed: {},
  methods: {
    submitForm(){
      this.$store.dispatch("config_module/createConfig", {
        form: this.form
      })
    }
  }
};
</script>

<style></style>
